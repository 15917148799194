import React from 'react'

function FunFact() {
  return (
    <div className="funfact">
      ...
    </div>
  )
}

export default FunFact