import React from 'react'
import RegisterForm from './RegisterForm'

function Register() {
  return (
    <div className="Register">
        <RegisterForm />
    </div>
  )
}

export default Register